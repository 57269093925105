<template>
  <KTPortlet :title="$t('SALARIES.TITLE')">
    <template v-if="loading" v-slot:body>
      <div class="text-center text-danger my-2">
        <b-spinner variant="danger"></b-spinner><br />
        <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
      </div>
    </template>
    <template v-else v-slot:body>
      <div>
        <b-row style="margin-bottom: 20px;">
          <b-col md="3" lg="2">
            <label>راپور را انتخاب کنید: </label>
          </b-col>
          <b-col md="4" lg="3">
            <b-form-select
              v-model="selectedReport"
              :options="reportsList"
              @change="getSalaryReport"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
      <b-card
        border-variant="dark"
        header-bg-variant="dark"
        header-text-variant="white"
        :header="$t('GENERAL.DESCRIPTION')"
        align="left"
        v-if="description"
      >
        <b-card-text>
          {{ description }}
        </b-card-text>
      </b-card>
      <b-card
        border-variant="dark"
        header-bg-variant="dark"
        header-text-variant="white"
        :header="$t('GENERAL.DETAILS')"
        align="left"
        style="margin-top: 20px;"
        v-if="details"
      >
        <b-card-text>
          <template v-for="detail in details">
            <b-row :key="detail.id">
              <template v-for="(item, key, index) in detail">
                <b-col :key="'col' + index">
                  <label>{{ key }}:</label>
                </b-col>
                <b-col
                  :class="
                    index !== Object.keys(detail).length - 1
                      ? 'border-right border-dark'
                      : ''
                  "
                  :key="index"
                >
                  <span>{{ item }}</span>
                </b-col>
              </template>
            </b-row>
            <hr :key="detail.id" />
          </template>
        </b-card-text>
      </b-card>
      <b-card
        border-variant="dark"
        header-bg-variant="dark"
        header-text-variant="white"
        align="left"
        style="margin-top: 20px;"
        v-else
      >
        <b-card-text>
          <b-row>
            <b-col>
              <label>{{ $t("SALARIES.NO_RECORDS") }}</label>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </template>
  </KTPortlet>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";

export default {
  components: {
    KTPortlet
  },
  data() {
    return {
      description: null,
      details: [],
      loading: true,
      reportsList: [],
      selectedReport: null
    };
  },
  methods: {
    getReportsList() {
      this.loading = true;
      ApiService.get("lecturer/get-salaries-list").then(({ data }) => {
        this.reportsList = data;
        this.selectedReport =
          data !== undefined && data.length !== 0 ? data[0].value : null;
        this.loading = false;
        this.getSalaryReport();
      });
    },
    getSalaryReport() {
      this.loading = true;
      ApiService.get(
        "lecturer/get-salaries" +
          (this.selectedReport != null ? "/" + this.selectedReport : "")
      ).then(({ data }) => {
        this.details = data.details;
        this.description = data.description;
        this.loading = false;
      });
    }
  },
  mounted() {
    this.getReportsList();
  }
};
</script>
